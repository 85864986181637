import { SystemProps, Flex } from '@storyofams/react-ui';
import { Button, Heading } from '~components';

type LinkListBlockProps = {
  content: {
    Title?: string,
    list?: Array<{
      image?: {
        filename?: string,
        alt?: string,
      },
      label?: string,
      url?: {
        cached_url?: string,
      };
      _uid?: string,
    }>;
  };
  customProps?: any;
} & SystemProps;

export const LinkListBlock = ({ content, customProps, ...props }: LinkListBlockProps) => {
  let localCustomFontProps = {} as any;

  if (!!customProps?.color) {
    localCustomFontProps.color = customProps?.color
  }

  return (
    <Flex
      flexDirection={'column'}
      width={['100%', 'min-content']}
      whiteSpace={'nowrap'}
      {...props}
    >
      {!!content?.Title
        ? (
          <Heading
            as="h3"
            width="100%"
            variant="sh3"
            fontSize={2.5}
            lineHeight={'150%'}
            fontWeight="800"
            {...localCustomFontProps}
          >{content?.Title}</Heading>
        ) : null
      }
      {!!content?.list && content?.list.length
        ? (
          <Flex flexDirection={'column'} gap={[1, 2, 3]} mt={[2, 5]}>
            {content?.list.map(listItem => (
              <Flex key={listItem._uid}>
                {!!listItem?.image?.filename && (
                  <Flex width={'24px'} px={0} mr={1.5}>
                    <img width={'100%'} src={listItem.image?.filename} />
                  </Flex>
                )}
                <Button
                  variant="link"
                  fontSize={2}
                  textDecoration={'none'}
                  lineHeight={'150%'}
                  fontWeight="600"
                  textAlign={'left'}
                  to={listItem.url.cached_url}
                  {...localCustomFontProps}
                >
                  {listItem.label}
                </Button>
              </Flex>
            ))}
          </Flex>
        )
        : null
      }
    </Flex>
  );
};
